<template>
  <div class="pt-16">
    <section class="bg-gray-100 bg-opacity-50 p-2 h-screen w-full">
      <div class="mx-auto container max-w-5xl bg-gray-300 shadow-md">
        <div
          class="bg-gray-100 p-4 border-t-4 bg-opacity-5 border-green-500 rounded-t"
        >
          <div class="max-w-sm mx-auto md:w-full md:mx-0">
            <div class="inline-flex items-center space-x-4">
              <img
                class="w-10 h-10 object-cover rounded-full"
                alt="User avatar"
                src="https://www.flaticon.com/svg/static/icons/svg/2922/2922539.svg"
              />
              <h1 class="text-gray-700 font-semibold capitalize"></h1>
            </div>
          </div>
        </div>

        <div class="bg-white space-y-6">
          <div
            class="md:inline-flex md:space-y-0 w-full p-2 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{ $t("email") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">mail</i>
                </div>
                <span class="p-2">{{ user.email }}</span>
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{ $t("role") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">lock</i>
                </div>
                <input
                  type="text"
                  v-model="user.role.name"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="role"
                  disabled
                />
              </div>
            </div>
          </div>

          <div
            class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{ $t("fullname") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.fullName"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="fullName"
                />
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{ $t("phone") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.phone"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="phone"
                />
              </div>
            </div>
          </div>

          <div
            class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{ $t("username") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.username"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="username"
                  @keydown.space="(event) => event.preventDefault()"
                  @keydown="nameKeydown($event)"
                  :disabled="isDisabled"
                />
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{ $t("store") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">business</i>
                </div>
                <input
                  type="text"
                  v-model="user.company"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="company"
                />
              </div>
            </div>
          </div>
          <div
            v-if="user.type == 'Seller'"
            class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{ $t("rib") }}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">call_to_action</i>
                </div>
                <input
                  type="text"
                  v-model="user.rib"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  :placeholder="$t('rib')"
                />
              </div>
            </div>
            <div class="md:w-2/3 max-w-sm mx-auto"></div>
          </div>
          <hr />

          <div
            class="md:inline-flex w-full space-y-4 md:space-y-0 justify-center text-gray-600 items-center"
          >
            <span class="text-gray-700 font-semibold"
              >{{ $t("change_password") }}
            </span>
            <div class="md:w-1/12 justify-end text-center md:pl-6">
              <button
                class="text-white hover:bg-green-600 rounded-full bg-green-600 flex items-center focus:outline-none p-2 ml-2"
              >
                <i class="material-icons">keyboard_arrow_up</i>
              </button>
            </div>
          </div>

          <hr />

          <div
            class="md:inline-flex w-full space-y-4 md:space-y-0 p-4 text-gray-600 items-center"
          >
            <div
              class="md:w-5/12 w-full md:pl-9 max-w-sm mx-auto space-y-5 md:inline-flex pl-2"
            >
              <div class="w-full inline-flex border-b hover:border-green-500">
                <div class="w-1/12 pt-2">
                  <svg
                    fill="none"
                    class="w-6 text-gray-500 mx-auto"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  v-model="user.password"
                  class="w-11/12 focus:outline-none focus:text-gray-600 p-2 ml-4"
                  placeholder="New password"
                />
              </div>
            </div>

            <div
              class="md:w-5/12 w-full md:pl-9 max-w-sm mx-auto space-y-5 md:inline-flex pl-2"
            >
              <div class="w-full inline-flex border-b hover:border-green-500">
                <div class="w-1/12 pt-2">
                  <svg
                    fill="none"
                    class="w-6 text-gray-500 mx-auto"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  v-model="user.confirmPassword"
                  class="w-11/12 focus:outline-none focus:text-gray-600 p-2 ml-4"
                  placeholder="Confirm password"
                />
              </div>
            </div>
          </div>
          <hr />
          <div
            class="md:inline-flex w-full space-y-4 justify-center md:space-y-0 p-2 text-gray-600 items-center"
          >
            <div class="md:w-3/12 text-center md:pl-6 pb-8">
              <button
                :disabled="loading"
                @click="update"
                class="text-white w-full mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 inline-flex items-center focus:outline-none md:float-right"
              >
                <i class="material-icons mr-8">save </i>
                {{ $t("save") }}
              </button>
            </div>
          </div>

          <div v-if="user.status == 'inactive'" class="ml-20 mr-20 pb-20">
            <div
              class="alert flex flex-row items-center bg-yellow-200 p-5 rounded border-b-2 border-yellow-300"
            >
              <div
                class="alert-icon flex items-center bg-yellow-100 border-2 border-yellow-500 justify-center h-10 w-10 flex-shrink-0 rounded-full"
              >
                <span class="text-yellow-500">
                  <svg fill="currentColor" viewBox="0 0 20 20" class="h-6 w-6">
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="alert-content ml-4">
                <div class="alert-title font-semibold text-lg text-yellow-800">
                  {{ $t("warning") }}
                </div>
                <div class="alert-description text-sm text-yellow-600">
                  {{ $t("must_shange_pass") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DivFilter from "../components/DivFilter.vue";
export default {
  components: { DivFilter },
  name: "Profil",
  data() {
    return {
      user: {
        role: {},
      },
      isDisabled: false,
      show: false,
      loading: false,
    };
  },
  async mounted() {
    this.token = this.$jwtService.verifyToken();
    this.getUser();
  },
  methods: {
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) {
        if (res.content.username) {
          this.isDisabled = true;
        }
        this.user = res.content;
      }
    },

    showBar() {
      if (this.show) this.show = false;
      else this.show = true;
    },
    async update() {
      if (this.user.type == "Seller") {
        if (!this.user.rib) {
          alert("Rib required !", "warning");
          return false;
        } else {
          if (isNaN(this.user.rib)) {
            alert(this.$t("rib_number"), "warning");
            return false;
          }
        }
        if (!this.user.company) {
          alert("Store name required !", "warning");
          return false;
        }
      }
   this.$swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
          //

          if (this.user.password)
            this.user.password = this.user.password.trim();
          if (this.user.confirmPassword)
            this.user.confirmPassword = this.user.confirmPassword.trim();

          //  if (this.user.status == "inactive" && this.user.password) {
          if (this.user.password) {
            if (this.checkPassword()) {
              // this.user.status = "active";
              this.loading = true;
              const data = await this.$server.update("users", this.user);
              this.loading = false;
              if (data && data._id) {
                alert("Profil updated", "success");
                this.logout();
                this.user.password = "";
                this.user.confirmPassword = "";
              } else alert(data, "warning");
            }
          } else {
            this.loading = true;
            const data = await this.$server.update("users", this.user);
            this.loading = false;
            if (data && data._id) {
              alert("Profil updated", "success");
              this.user.password = "";
              this.user.confirmPassword = "";
            } else alert(data, "warning");
          }
        }
      });
    },
    checkPassword() {
      if (this.user.password == this.user.confirmPassword) {
        return true;
      } else {
        alert("Confirmation password does not match ", "warning");
        return false;
      }
    },
    checkData() {
      if (this.user.email) return true;
      else return false;
    },
    logout() {
      this.$jwtService.destroyToken();
      location.href = "/";
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style>
.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #3182ce;
  left: 100%;
  top: 0;
}
</style>
