<template>
  <div class="pt-4 px-3 p-3">
    <div id="config" class="flex flex-col border p-4 rounded-lg bg-white">
      <div v-if="name != 'userHistories'" class="flex flex-row">
        <div class="flex w-full">
          <input
            :placeholder="$t(config.filters.elements.search.options.hint)"
            v-model="searchText"
            @keyup.enter="trigger"
            type="search"
            autofill="off"
            class="border-t text-gray-700 text-xs border-b border-l rounded-l-md w-full focus:outline-none py-1 px-4"
          />
          <button
            @click="search"
            ref="sendReply"
            class="bg-white rounded-r-md text-green-500 border-t border-r border-b hover:opacity-75 px-3 focus:outline-none"
          >
            <i class="py-1 material-icons">search</i>
          </button>
        </div>
        <div class="">
          <button
            @click="showMore = !showMore"
            class="text-white hover:bg-gray-400 rounded-full bg-green-600 flex items-center focus:outline-none p-2 ml-2"
          >
            <i v-if="showMore" class="material-icons">keyboard_arrow_up</i>
            <i v-else class="material-icons">keyboard_arrow_down</i>
          </button>
        </div>
      </div>
      <div
        class="flex flex-col"
        v-if="name == 'userHistories' ? !showMore : showMore"
      >
        <div class="flex flex-row space-x-3 justify-center flex-wrap">
          <div
            v-for="(item, index) in config.filters.elements.champs"
            :key="index"
            v-show="isShown(item)"
          >
            <div
              v-if="getShow(item.key) && item.key != 'from' && item.key != 'to'"
              class="w-56 mb-4 mt-6"
            >
              <div
                class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="seller" class="bg-white text-gray-700 px-1"
                      >{{ $t(item.label) }}
                      <span :class="$colors.required">*</span></label
                    >
                  </p>
                </div>
                <div v-if="item.type == 'select' && getShow(item.key)">
                  <div v-if="item.name">
                    <span v-if="item.key == 'product'">
                      <v-select
                        :label="item.name"
                        v-model="filter[item.key]"
                        :options="products"
                      ></v-select>
                    </span>
                    <span v-else>
                      <v-select
                        :class="
                          config.name === 'orders' &&
                          item.key === 'status_pending' &&
                          (!checkstatusorder || checkstatusorder != 'Pending')
                            ? 'disabled'
                            : ''
                        "
                        @input="CheckSellerProducts"
                        @search="searchUsers($event, item.key)"
                        :label="item.name"
                        v-model="filter[item.key]"
                        :options="item.values"
                      ></v-select>
                    </span>
                  </div>
                  <div v-else>
                    <v-select
                      v-model="filter[item.key]"
                      :options="item.values"
                    ></v-select>
                  </div>
                </div>
                <div v-else-if="item.type == 'number'">
                  <input
                    :min="item.min"
                    :step="item.step"
                    v-model="filter[item.key]"
                    :type="item.type"
                    :name="item.key"
                    :id="item.key"
                    :class="item.class"
                  />
                </div>
                <div v-else>
                  <!-- <input  v-model="filter[item.key]" :type="item.type" :placeholder="item.placeholder"  :name="item.key" :id="item.key" :class="item.class"> -->

                  <input
                    v-if="item.type != 'date_range'"
                    v-model="filter[item.key]"
                    :placeholder="item.placeholder"
                    :type="item.type"
                    :name="item.key"
                    :id="item.key"
                    :class="item.class"
                  />
                  <date-picker
                    v-else
                    :placeholder="item.placeholder"
                    @change="DateRange"
                    format="YYYY-MM-DD"
                    class="w-full-imp"
                    v-model="filter[item.key]"
                    range
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="config.filters.hasFilterdate"
          class="flex flex-row space-x-3 justify-center flex-wrap"
        >
          <div
            v-for="(item, index) in config.filters.elements.champs"
            :key="index"
            v-show="isShown(item)"
          >
            <div
              v-if="
                getShow(item.key) && (item.key == 'from' || item.key == 'to')
              "
              class="w-56 py-2"
            >
              <div
                class="border h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="seller" class="bg-white text-gray-700 px-1"
                      >{{ item.label }}
                      <span :class="$colors.required">*</span></label
                    >
                  </p>
                </div>
                <input
                  v-model="filter[item.key]"
                  :type="item.type"
                  :name="item.key"
                  :id="item.key"
                  :class="item.class"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-center space-x-2 mt-1">
          <button
            @click="ClearFilters"
            class="text-green-500 rounded-md mx-1 border border-gray-100 bg-gray-300 hover:bg-green-600 w-1/6 hover:text-white py-2 px-4 focus:outline-none"
          >
            {{ $t("reset") }}
          </button>
          <button
            v-if="config.filters.elements.champs.length > 0"
            @click="filtrer_saerch"
            class="rounded-md mx-1 border border-gray-100 bg-green-600 hover:bg-green-600 text-white py-2 px-4 w-1/6 focus:outline-none"
          >
            {{ $t("filtrer") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: { type: Object },
    currentUser: { type: Object },
    idWharhouse: { type: Object },
    name: { type: String },
  },
  data() {
    return {
      showMore: false,
      searchText: "",
      warhouseCountry: "",
      filter: {},
      //idWharhouse:null,
      products: [],
      checkstatusorder: null,
    };
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //  await this.getwharhouseId();
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.getCurrentSellerProducts();
    },
  },

  async mounted() {
    this.warhouseCountry = await this.warhouseSelected;
    //await this.getwharhouseId();
    this.config.filters.elements.champs.forEach((filter) => {
      if (filter.value)
        this.$set(this.filter, filter.key, filter.value, filter.values);
    });

    await this.getCurrentSellerProducts();
  },
  methods: {
    DateRange(data) {},
    getShow(champ) {
      if (
        (champ === "seller" || champ === "courier" || champ === "zone") &&
        this.currentUser.type === "Seller"
      )
        return false;
      if (champ === "seller" && this.currentUser.type === "Seller")
        return false;
      if (
        champ === "status" &&
        (this.currentUser.type === "CancelledOrder" ||
          this.currentUser.type == "ToRemind")
      )
        return false;
      if (
        champ === "status_pending" &&
        (this.currentUser.type === "CancelledOrder" ||
          this.currentUser.type == "ToRemind")
      )
        return false;
      else return true;
    },
    trigger() {
      this.$refs.sendReply.click();
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getCurrentSellerProducts() {
      this.products = [];

      if (this.currentUser && this.currentUser.type == "Seller") {
        const filters = {
          seller: this.currentUser._id,
          "details.warehouse": this.idWharhouse._id,
          limit: 4000,
        };

        const res = await this.$server.search("products", filters);

        if (res.content.results) {
          this.products = res.content.results;
        } else this.products = [];
      }
    },
    async CheckSellerProducts(seller) {
      if (this.name == "orders" && seller && seller.name && seller.color) {
        this.checkstatusorder = seller.name;
      }
      if (
        (this.name == "shippings" || this.name == "orders") &&
        seller &&
        seller.email
      ) {
        this.products = [];
        const filters = {
          seller: seller._id,
          "details.warehouse": this.idWharhouse._id,
          limit: 4000,
        };

        const res = await this.$server.search("products", filters);

        if (res.content.results) {
          this.products = res.content.results;
        } else this.products = [];
      }
    },
    reset: function (val) {
      this.$emit("reset", val);
    },
    filtrer: function (val) {
      this.$emit("filtrer", val);
    },
    search: function () {
      this.$emit("search", this.searchText);
    },
    searchUsers: async function (value, event) {
      if (event == "seller" || event == "user") {
        this.$emit("searchSellers", value, event);
      }
      if (event == "courier") this.$emit("searchCouriers", value, event);
    },
    isShown(item) {
      let shown =
        !item.show ||
        (item.show && this.filter[item.show.key] == item.show.equal);
      if (!shown) this.$set(this.filter, item.key, null);
      return shown;
    },
    searc() {},

    /*********************************************************************************** */
    async filtrer_saerch() {
      this.filtrer(this.filter);
    },
    async ClearFilters() {
      this.reset(this.filter);
      this.filter = {};
    },
  },
};
</script>

<style></style>
